import React from 'react'
import styles from './new-client.module.sass'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Contact from '../components/contact'

const NewClientPage = () => (
   <Layout>
      <SEO title="Become A Client" />
      <div className={['page', styles.page__new_client].join(' ')}>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               Become A Client
            </h1>
            <p className={['page--subheader', styles.page__subheader].join('')}>
               It's our mission to help meet your financial needs. Let's connect
               so we can lend you our helping hand.
            </p>
         </div>
         <div className={styles.content}>
            <Contact page="new-client" />
         </div>
      </div>
   </Layout>
)

export default NewClientPage
